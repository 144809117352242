export const mainMenuItems = [
  {
    name: 'HOME',
    stateName: 'main.home',
  },
  {
    name: 'CATALOG',
    stateName: 'main.catalog.tile',
  },
  {
    name: 'FAQs',
    href: 'https://upwardbound.helpsite.com/',
    target: '_blank',
  },
  {
    name: 'CONTACT',
    stateName: 'main.contact',
  },
  {
    name: 'ABOUT',
    stateName: 'main.about',
  },
  {
    name: 'ADMIN',
    href: '/lms/admin',
    type: 'custom',
    data: { availableFor: ['admin'] },
  },
];
